import React, { FunctionComponent, useEffect, useState } from 'react';

import Button, { ButtonIcons, ButtonThemes } from '../../../../components/Button';
import { formatDateString } from '../../../../shared/helpers';
import InputWrapper from '../../../../components/InputWrapper';
import { RankingCalendarDto } from '../../../RankingProfile';
import { getCalendarsRequest } from '../../../RankingsHistory/rankings.api';
import Typography, { TypographyVariants } from '../../../../components/Typography';

import styles from './styles.module.scss';

const RankingsCalendar: FunctionComponent = () => {
  const [compact, setCompact] = useState(true);
  const [calendarsData, setCalendarsData] = useState<RankingCalendarDto[]>([]);

  useEffect(() => {
    // TODO: use a redux action, not directly the api request
    getCalendarsRequest().then(({ data }) => {
      setCalendarsData(data);
    });
  }, []);

  return (
    <section className="section">
      <div className={styles.header}>
        <Typography component="h3" variant={TypographyVariants.h3} className="mba-section-heading-title">
          FT Ranking&apos;s calendar
        </Typography>
        <span className={styles.icon}></span>
      </div>
      <div className={`${styles.content} mba-background--white`}>
        {calendarsData.slice(0, compact ? 3 : undefined).map(({ openDate, publicationDate, rankingType }, i, data) => (
          <div key={rankingType.id} className={styles.ranking}>
            <Typography component="h5" variant={TypographyVariants.h5} className="title">
              <span className={`${styles.name}`}>{rankingType?.name}</span>
            </Typography>
            <div className={styles.datesCouple}>
              <InputWrapper title="Open">{formatDateString(openDate, 'MMM YYYY')}</InputWrapper>
              <InputWrapper title="Publication">{formatDateString(publicationDate, 'MMM YYYY')}</InputWrapper>
            </div>
            {i < data.length - 1 && <hr className="mba-separator" />}
          </div>
        ))}
        {calendarsData.length > 3 && (
          <>
            <hr className="mba-separator" />
            <div className={styles.buttonWrapper}>
              <Button
                icon={compact ? ButtonIcons.arrowDown : ButtonIcons.arrowUp}
                iconPosition="right"
                text={`${compact ? 'Show' : 'Hide'} full list of rankings`}
                theme={ButtonThemes.basic}
                onClick={(): void => setCompact((compact) => !compact)}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default RankingsCalendar;
