import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment-timezone';

import {
  ASCENDING_ORDER_DIRECTION,
  DESCENDING_ORDER_DIRECTION,
  FORBIDDEN_PATH,
  OrderDirection,
  PageFilterConfig,
  PageSearchQueryConfig,
  TableListData,
  SERVER_UNAVAILABLE_PATH,
  LOGIN_PATH,
} from './constants';
import { FormValue } from '../components/Form';
import { Answer, LeadsDTO, LeadsTableDTO } from '../containers/Leads/leads.dto';

export const getTableListDefaultState = <T>(): TableListData<T> => ({
  data: [] as T[],
  pagination: {
    page: 1,
    limit: 10,
    totalCount: 0,
    totalPages: 1,
  },
  sortOrder: {
    order: DESCENDING_ORDER_DIRECTION as OrderDirection,
    sortBy: '',
  },
  search: '',
  filter: {} as PageFilterConfig,
});

export const compareObjects = (key: string, order = ASCENDING_ORDER_DIRECTION) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (firstObject: any, secondObject: any): number => {
    if (!firstObject.hasOwnProperty(key) || !secondObject.hasOwnProperty(key)) {
      return 0;
    }

    const firstValue =
      typeof firstObject[key] === 'string'
        ? firstObject[key].toUpperCase()
        : firstObject[key] && firstObject[key].label
        ? firstObject[key].label
        : firstObject[key];

    const secondValue =
      typeof secondObject[key] === 'string'
        ? secondObject[key].toUpperCase()
        : secondObject[key] && secondObject[key].label
        ? secondObject[key].label
        : secondObject[key];

    let comparison = 0;
    if (firstValue > secondValue) {
      comparison = 1;
    } else if (firstValue < secondValue) {
      comparison = -1;
    }

    return order === DESCENDING_ORDER_DIRECTION ? comparison * -1 : comparison;
  };
};

export const redirectUserToLogin = (): void => {
  const isAuthRoute = window.location.pathname.startsWith('/auth');
  if (typeof window !== 'undefined' && !isAuthRoute) {
    window.location.href = LOGIN_PATH;
  }
};

export const redirectUserToForbiddenUrl = (errorMessage = ''): void => {
  const isSameCurrentRoute = window.location.pathname === FORBIDDEN_PATH;
  if (typeof window !== 'undefined' && !isSameCurrentRoute) {
    window.location.href = `${FORBIDDEN_PATH}${errorMessage === 'Inactive user' ? '?isActive=false' : ''}`;
  }
};

export const redirectUserToServerUnavailable = (): void => {
  const isSameCurrentRoute = window.location.pathname === SERVER_UNAVAILABLE_PATH;
  if (typeof window !== 'undefined' && !isSameCurrentRoute) {
    window.location.href = SERVER_UNAVAILABLE_PATH;
  }
};

export const generateSearchQuery = (pageConfig: PageSearchQueryConfig): string => {
  const { filter, limit, order, page, sortBy, search } = pageConfig;
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  if (order) {
    queryParams.push(`order=${order}`);
  }

  if (search) {
    queryParams.push(`search=${search.trim()}`);
  }

  if (filter) {
    queryParams.push(
      Object.entries(filter).reduce(
        (filterQuery, [key, value]): string =>
          value.reduce(
            (currentFilterQuery, filterValue): string =>
              `filter[${key}]=${filterValue}${currentFilterQuery ? `&${currentFilterQuery}` : ''}`,
            '',
          ) + `${filterQuery ? `&${filterQuery}` : ''}`,
        '',
      ),
    );
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};

export const capitalizeText = (text: string): string => {
  const textString = text ? text.toString() : '';
  return textString.charAt(0).toUpperCase() + textString.slice(1);
};

export const mapOrigamiComponentsToQuery = (components: string[]): string =>
  components.reduce((componentsList, origamiComponentString: string) => {
    return componentsList ? `${componentsList},${origamiComponentString}` : origamiComponentString;
  }, '');

export const formatDateString = (date?: string, dateFormat = 'MM/DD/YYYY'): string => {
  let formattedPublicationDate = '';

  if (date) {
    formattedPublicationDate = moment(date).tz('Etc/GMT').format(dateFormat);
  }

  return formattedPublicationDate;
};

export const getFormErrors = <T extends Record<string, string[]>, K extends keyof T>(
  objectData: T,
  key: K,
): string[] => {
  let errors: string[] = [];

  if (objectData.hasOwnProperty(key)) {
    errors = objectData[key];
  }

  return errors;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPropertyValue = <T extends Record<string, any>, K extends keyof T>(
  objectData: T,
  key: K,
): FormValue => {
  let value: FormValue = '';

  if (objectData.hasOwnProperty(key)) {
    let currentProperty: FormValue = objectData[key] ?? '';

    if (
      currentProperty !== null &&
      typeof currentProperty === 'object' &&
      !Array.isArray(currentProperty) &&
      currentProperty['key']
    ) {
      currentProperty = currentProperty['key'];
    }

    value = currentProperty;
  }

  return value;
};

export function getHtmlFromJSON(content: string): string {
  // Added backwards compatibility, prior to draft-js
  try {
    const contentState = convertFromRaw(JSON.parse(content) as unknown as RawDraftContentState);
    // It's already a JSON, meaning the new format. Handle it.
    return contentState.hasText() ? stateToHTML(contentState) : '-';
  } catch (e) {
    // Otherwise, it means it's the old plain format, prior to draft-js
    return content;
  }
}

const getExperience = (name: string, answers: Answer[]): string | undefined => {
  if (name === 'Prospective student') {
    return answers.find((answer) => {
      return answer.question.id === 2;
    })?.value;
  }
};

export const convertToLeadsTableData = (data: LeadsDTO[]): LeadsTableDTO[] => {
  return data.map((item: LeadsDTO) => {
    const user = item.user;
    const { answers = [] } = user;
    const { name = '' } = user.role;
    return {
      id: item.id,
      name: `${user.firstName} ${user.lastName}`,
      'user.country': user.country.name,
      'question.experience': getExperience(name, answers) || '-',
      createdAt: item.createdAt,
      status: item.status,
    };
  });
};

export const getRenamedFile = (file: File, newFileName: string): File => {
  return new File([file], newFileName, {
    type: file.type,
  });
};

export const goToPublicPortal = (url: string): void => {
  const publicPortalUrl = process.env.REACT_APP_MBA_PUBLIC_PORTAL;
  if (typeof window !== 'undefined') {
    window.open(`${publicPortalUrl}/${url}`);
  }
};
